.checkin {
  cursor: pointer;
}

.rooms {
  cursor: pointer;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.children {
 position: relative;
}

.calendar {
  background-color: #f5f4ee;
  width: 350px !important;
  height: 350px !important;
  border: 0px !important;
}

.calendar-body {
  border: 0;
  grid-template-rows: repeat(7, 1fr) !important;
  margin-top: 10px;
}


.calendar-button {
  background-color: #8F6060 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.calendar-header {
  font-size: 18px !important;
}

.cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f4ee;
  border-radius: 50px;
  width: 100%;
  height: 100%;
} */

.cell {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 8px;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.cell:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.cell span {
  display: inline-block;
  vertical-align: middle;
}

.disabled {
  text-decoration: line-through;
  text-decoration-thickness: 10%;
  cursor: not-allowed !important;
}

.selected {
  background-color: #8F6060;
  color: white;
}

.isBetween {
  background-color: gray;
  color: white;
}

.cell:hover {
  background-color: #8F6060;
  color: white;
  cursor: pointer;
}

.empty-cell {
  background-color: #f5f4ee;
}

.calendar-weekday {
  background-color: #f5f4ee;
}

@media only screen and (max-width: 700px) {
  .booking {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    height: auto !important;
    margin: 0px;
    width: 100%;
  }

  .checkin {
    width: 100%;
    margin: 0 auto 10px auto;
  }

  .rooms {
    width: 100%;
    margin: 0 auto 10px auto;
  }

  .button {
    width: 100%;
    margin: 0 auto 10px auto;
  }
}